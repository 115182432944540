import VanillaTilt from 'vanilla-tilt';

document.addEventListener('DOMContentLoaded', () => {
  // Utility functions
  const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
  const getScrollTop = () => window.pageYOffset || document.documentElement.scrollTop;

  // DOM Elements
  const planetsWrapper = document.querySelector('.planets-wrapper');
  const venDiagramContainer = document.querySelector('.manifesto-ven-diagram');
  const navigation = document.querySelector('.main-navigation');
  const aboutSection = document.querySelector('#about');
  const sections = document.querySelectorAll('section[id]');
  const navLinks = document.querySelectorAll('.nav-links a');
  const images = document.querySelectorAll('img');
  const leftCircle = document.querySelector('#left-circle');
  const rightCircle = document.querySelector('#right-circle');
  const venDiagram = document.querySelector('.manifesto-ven-diagram-container');

  // Initial setup
  // document.body.classList.add('hidden');

  // Calculate scroll progress relative to an element
  const calculateProgress = (element) => {
    const scrollY = getScrollTop();
    const elementTop = element.getBoundingClientRect().top + scrollY;
    const windowHeight = window.innerHeight;
    const elementHeight = element.offsetHeight;
    const start = clamp(scrollY - elementTop, 0, elementHeight - windowHeight);
    return start / (elementHeight - windowHeight);
    
  };

  // Handle scroll animations for Venn diagram
  const handleScrollAnimations = () => {
    const lerp = (x, y, a) => x * (1 - a) + y * a;
    const progress = calculateProgress(venDiagramContainer);
    const isWideScreen = window.matchMedia('(min-width: 920px)').matches;
    const leftCircleWidth = isWideScreen ? 30 : 26;
    const startValue = isWideScreen ? 4 : 12;
    const endValue = 60;
    const offsetFactor = isWideScreen ? 'vw' : 'vh';
    const offset = lerp(0, leftCircleWidth, progress);
    const v = lerp(startValue, endValue, progress);

    leftCircle.style.transform = `translate${isWideScreen ? 'X' : 'Y'}(calc(${clamp(v, startValue, 50)}${offsetFactor} - ${clamp(offset, 0, (leftCircleWidth/2))}${offsetFactor}))`;
    rightCircle.style.transform = `translate${isWideScreen ? 'X' : 'Y'}(calc(-${clamp(v, startValue, 50)}${offsetFactor} + ${clamp(offset, 0, (leftCircleWidth/2))}${offsetFactor}))`;

    if (v >= 50) venDiagram.classList.add('blue');
    else venDiagram.classList.remove('blue');
  };

  // Handle navigation visibility and active link updates
  const handleNavigation = () => {
    navigation.style.opacity = aboutSection.getBoundingClientRect().top <= 0 ? '1' : '0';

    sections.forEach((section) => {
      const scrollPosition = getScrollTop();
      const sectionTop = section.offsetTop;
      const sectionHeight = section.clientHeight;

      if (scrollPosition >= sectionTop - 100 && scrollPosition < sectionTop + sectionHeight - 100) {
        let activeId = section.id === 'manifesto' ? 'about' : section.id;
        navLinks.forEach((link) => link.classList.remove('active'));
        document.querySelector(`.nav-links a[href="#${activeId}"]`)?.classList.add('active');
      }
    });
  };

  // Show content after all images have loaded
  // const showPageContent = () => {
  //   document.body.classList.add('visible');
  //   document.body.classList.remove('hidden');
  // };

  // // Check if all images are loaded
  // const checkImagesLoaded = () => {
  //   const allLoaded = Array.from(images).every((img) => img.complete);
  //   if (allLoaded) showPageContent();
  //   else {
  //     let loadedImagesCount = 0;
  //     images.forEach((img) => {
  //       const handleImageLoad = () => {
  //         loadedImagesCount++;
  //         if (loadedImagesCount === images.length) showPageContent();
  //       };
  //       img.addEventListener('load', handleImageLoad);
  //       img.addEventListener('error', handleImageLoad);
  //     });
  //   }
  // };

  // Initialize VanillaTilt
  const initializeTilt = () => {
    VanillaTilt.init(document.querySelectorAll('.client-logo'), {
      max: 25,
      speed: 400,
      scale: 1.05,
      glare: true,
      'max-glare': 0.15,
    });
  };

  // Set up hover interactions for logos
  const setupLogoHover = () => {
    const clientLogos = document.querySelectorAll('.client-logo');
    
    // Disable interactions on mobile
    if (window.matchMedia('(max-width: 920px)').matches) {
      clientLogos.forEach(logo => {
        logo.style.cursor = 'default'; // Change cursor to default
        logo.style.pointerEvents = 'none'; // Disable pointer events
      });
      return; // Exit the function to prevent further processing
    }

    clientLogos.forEach((logo) => {
      const svg = logo.querySelector('svg');
      const caption = logo.querySelector('p');
      
      // Hide caption on mobile
      if (caption) {
        caption.style.display = 'none'; // Ensure caption is hidden on mobile
      }

      if (!caption || !caption.textContent.trim()) {
        logo.style.cursor = 'default';
        return;
      }

      let isShowingText = false;
      logo.style.cursor = 'pointer';

      const toggleContent = () => {
        isShowingText = !isShowingText;
        svg.style.display = isShowingText ? 'none' : 'block';
        caption.style.display = isShowingText ? 'block' : 'none';
      };

      logo.addEventListener('mouseenter', toggleContent);
      logo.addEventListener('mouseleave', (e) => {
        if (!logo.contains(e.relatedTarget) && isShowingText) toggleContent();
      });
    });
  };
  

  // Initialize page functionality
  const init = () => {
    // checkImagesLoaded();
    initializeTilt();
    setupLogoHover();
    handleNavigation();
    window.addEventListener('scroll', handleNavigation);
    requestAnimationFrame(() => {
      window.addEventListener('scroll', handleScrollAnimations);
    });
  };

  init();
});